.App {
  text-align: center;
  background-color: #deecff;
  height: 100%;
  width: 100%;
}

a {
  background-image: linear-gradient(to right, #004aad, #004aad 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
}

a:before {
  content: "";
  background: #004aad;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-position: 0;
}

a:hover::before {
  width: 100%;
}
